export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: "2",
    boxSizing: "border-box",
    // overflowY: "scroll",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  tiles: {
    display: "flex",
    ...theme.flexColumnCenter,
    justifyContent: "center",
    flexWrap: "wrap",
    "-webkit-flex-flow": "row wrap",
  },
  empty: {
    padding: theme.spacing(4),
  },

  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  blinkButton: {
    animationName: "$blinker",
    animationDuration: "0.9s",
    animationTimingFunction: "jump-both",
    animationIterationCount: "infinite",

    opacity: "0",
    width: "170px",
    [theme.breakpoints.down("xs")]: {
      // display: "none",
      width: "110px",
    },
  },

  orgButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  legend: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  // paper: {
  //   [theme.breakpoints.down("xs")]: {
  //     width: "320px",
  //   },
  // },

  filterbox: {
    width: "auto",
    maxWidth: "1282px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },

  nameButton: {
    width: "190px",
    [theme.breakpoints.down("xs")]: {
      // display: "none",
      width: "190px",
    },
  },

  // filterBox: {
  //   background: theme.dark,
  //   palette: {
  //     primary: "#fff",
  //     divider: "#fff",
  //     background: {
  //       default: "#fff",
  //       paper: "#fff",
  //     },
  //     text: {
  //       primary: "#fff",
  //       secondary: "grey"[500],
  //     },
  //   },
  // },

  textField: {
    input: {
      color: "white",
    },
  },
});
