import React from "react";
import { useEffect } from "react";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
} from "constants/paths";
import styles from "./AvailabilityPage.styles";
import UC from "../../../../static/UC.png";
import { createTheme } from "@material-ui/core/styles";
import { TableContainer } from "@material-ui/core";
import firebase from "firebase/compat";
import AvailabilityJournaalTable from "../../AvailabilityJournaalTable";
const analytics = firebase.analytics();
firebase.analytics().logEvent(analytics, "page_view", {
  page_path: "/n3beschikbaarheidjournaal",
});

const useStyles = makeStyles(styles);

function Home() {
  const classes = useStyles();

  const MINUTE_MS = 4000000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Reloads every 4000000 miliseconds");
      window.location.reload(false);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <div style={{ background: "gray", marginTop: "-10px" }}>
      {/* <Typography variant="h3" component="h3" gutterBottom></Typography> */}
      {/* <TableContainer> */}

      {/* <Paper
          // role="article"
          // style={{ margin: "20px", width: "600px", wrap: "nowrap" }}
          style={{ margin: "20px", width: "600px" }}
        > */}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
        className={classes.section}
        wrap
        // style={{ position: "left" }}
        // style={{ marginTop: "10px", width: "900px", overflow: "scroll" }}

        style={{ marginLeft: "20px" }}
      >
        {/* <Paper variant="outlined"></Paper> */}
        <AvailabilityJournaalTable style={{ margin: "10px" }} />
        {/* <img src={UC} scale="5%" width="150px" height="auto" /> */}
        <ul>
          {/* <li>
              <Link to={LOGIN_PATH}>Login</Link>
            </li>
            <li>
              <Link to={SIGNUP_PATH}>Signup</Link>
            </li>
            <li>
              <Link to={ACCOUNT_PATH}>Account</Link>
            </li> */}
        </ul>
      </Grid>
      {/* </Paper> */}
      {/* </TableContainer> */}
    </div>
  );
}

export default Home;
