export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    paddingTop: theme.spacing(2),
  },
  section: {
    ...theme.flexColumnLeft,
    padding: theme.spacing(2),
    textAlign: "center",
  },
});
