// import HomePage from './components/HomePage'

// // Sync route definition
// export default {
//   path: '/',
//   component: HomePage
// }

import { loadable } from 'utils/router'
import { HOME_PATH as path } from 'constants/paths'

export default {
  path,
  component: loadable(() =>
    import(/* webpackChunkName: 'Home' */ './components/HomePage')
  )
}
