import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import TodayIcon from "@material-ui/icons/Today";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { useNotifications } from "modules/notification";

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  blueButton: {
    // position: "relative",
    // display: "flex",
    // marginBottom: 20,
    background: "#006699",
  },
  orangeButton: {
    // position: "relative",
    // display: "flex",
    // marginBottom: 20,
    background: "#f57f17",
    hover: "#f57f17",
  },
  greenButton: {
    // position: "relative",
    // display: "flex",
    // marginBottom: 20,
    background: "#388e3c",
  },
  greenOutlineButton: {
    // position: "relative",
    // display: "flex",
    // marginBottom: 20,
    color: "#388e3c",
  },
  redButton: {
    light: "#b9000b",
    main: "#b9000b",
    dark: "#ba000d",
    contrastText: "#ffffff",
    background: "#b9000b",
    color: "#ffffff",
  },
  redOutlineButton: {
    // position: "relative",
    // display: "flex",
    // marginBottom: 20,
    color: "#b9000b",
  },
}));

export function PopButtonBlue(props) {
  const classes = useStyles();
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const handleClickBlue = (event) => {
    let client = event.currentTarget.getAttribute("clientName");
    let bookingAction = event.currentTarget.getAttribute("bookingAction");
    let newStatus = "10";
    const newData = {
      client: event.currentTarget.getAttribute("clientName"),
      newStatus: "10",
    };

    const bookingId = props.selectedbookingid;

    updateBooking(bookingId, newData);
  };

  async function updateBooking(bookingId, booking) {
    try {
      await firestore.update(
        { collection: "bookings", doc: bookingId },
        {
          bookedForOrg: booking.client,

          bookingStatus: booking.newStatus,
        }
      );
      showSuccess("De boeking is aangepast!");
    } catch (err) {
      console.error("Fout bij aanpassen boeking", err); // eslint-disable-line no-console
      showError(`Fout bij aanpassen boeking: ${err.message}`);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={(classes.margin, classes.blueButton)}
        onClick={handleClickBlue}
        clientName={props.clientName}
        bookingAction={"prebook"}
      >
        <TodayIcon />
      </Button>
    </div>
  );
}

export function PopButtonGreenOutline(props) {
  const classes = useStyles();
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const handleClick = (event) => {
    let client = event.currentTarget.getAttribute("clientName");
    let bookingAction = event.currentTarget.getAttribute("bookingAction");
    let newStatus = "20";
    const newData = {
      client: event.currentTarget.getAttribute("clientName"),
      newStatus: "20",
    };

    const bookingId = props.selectedbookingid;
    updateBooking(bookingId, newData);
  };

  async function updateBooking(bookingId, booking) {
    try {
      await firestore.update(
        { collection: "bookings", doc: bookingId },
        {
          bookedForOrg: booking.client,

          bookingStatus: booking.newStatus,
        }
      );
      showSuccess("De boeking is aangepast!");
    } catch (err) {
      console.error("Fout bij aanpassen boeking", err); // eslint-disable-line no-console
      showError(`Fout bij aanpassen boeking: ${err.message}`);
    }
  }

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        className={(classes.margin, classes.greenOutlineButton)}
        color="greenOutlineButton"
        clientName={"beschikbaar"}
        bookingAction={"available"}
        onClick={handleClick}
      >
        Beschikbaar
      </Button>
    </div>
  );
}

export function PopButtonRedOutline(props) {
  const classes = useStyles();

  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const handleClick = (event) => {
    let client = event.currentTarget.getAttribute("clientName");
    let bookingAction = event.currentTarget.getAttribute("bookingAction");
    let newStatus = "50";
    const newData = {
      client: event.currentTarget.getAttribute("clientName"),
      newStatus: "50",
    };

    const bookingId = props.selectedbookingid;
    updateBooking(bookingId, newData);
  };

  async function updateBooking(bookingId, booking) {
    try {
      await firestore.update(
        { collection: "bookings", doc: bookingId },
        {
          bookedForOrg: booking.client,

          bookingStatus: booking.newStatus,
        }
      );
      showSuccess("De boeking is aangepast!");
    } catch (err) {
      console.error("Fout bij aanpassen boeking", err); // eslint-disable-line no-console
      showError(`Fout bij aanpassen boeking: ${err.message}`);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        className={(classes.margin, classes.redOutlineButton)}
        color="redOutlineButton"
        clientName={"niet beschikbaar"}
        bookingAction={"not available"}
        onClick={handleClick}
      >
        niet Beschikbaar
      </Button>
    </div>
  );
}

export function PopButtonRed(props) {
  const classes = useStyles();

  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const handleClick = (event) => {
    let client = event.currentTarget.getAttribute("clientName");
    let bookingAction = event.currentTarget.getAttribute("bookingAction");
    let newStatus = "40";
    const newData = {
      client: event.currentTarget.getAttribute("clientName"),
      newStatus: "40",
    };

    const bookingId = props.selectedbookingid;
    updateBooking(bookingId, newData);
  };

  async function updateBooking(bookingId, booking) {
    try {
      await firestore.update(
        { collection: "bookings", doc: bookingId },
        {
          bookedForOrg: booking.client,

          bookingStatus: booking.newStatus,
        }
      );
      showSuccess("De boeking is aangepast!");
    } catch (err) {
      console.error("Fout bij aanpassen boeking", err); // eslint-disable-line no-console
      showError(`Fout bij aanpassen boeking: ${err.message}`);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="redButton"
        size="small"
        className={(classes.margin, classes.redButton)}
        clientName={props.clientName}
        bookingAction={"book"}
        onClick={handleClick}
      >
        <EventAvailableIcon />
      </Button>
    </div>
  );
}

export function PopButtonOrange(props) {
  const classes = useStyles();
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const handleClickOrange = (event) => {
    let client = event.currentTarget.getAttribute("clientName");
    let bookingAction = event.currentTarget.getAttribute("bookingAction");
    let newStatus = "30";
    const newData = {
      client: event.currentTarget.getAttribute("clientName"),
      newStatus: "30",
    };

    const bookingId = props.selectedbookingid;
    updateBooking(bookingId, newData);
  };

  async function updateBooking(bookingId, booking) {
    try {
      await firestore.update(
        { collection: "bookings", doc: bookingId },
        {
          bookedForOrg: booking.client,

          bookingStatus: booking.newStatus,
        }
      );
      showSuccess("De boeking is aangepast!");
    } catch (err) {
      console.error("Fout bij aanpassen boeking", err); // eslint-disable-line no-console
      showError(`Fout bij aanpassen boeking: ${err.message}`);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={(classes.margin, classes.orangeButton)}
        clientName={props.clientName}
        bookingAction={"conditional book"}
        onClick={handleClickOrange}
      >
        <BookmarkIcon />
      </Button>
    </div>
  );
}
