import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";

import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Vacature from "../../static/vacature.jpg";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import {
  LOGIN_PATH,
  ACCOUNT_PATH,
  USERS_PATH,
  ORGANISATIONS_PATH,
  BOOKINGS_PATH,
  PLANNERSCHEDULE_PATH,
  BOOKERSCHEDULE_PATH,
  PUSHMESSAGETEST_PATH,
  AVAILABILITY_PATH,
  HOME_PATH,
  TEAM_PATH,
  REEL_PATH,
} from "constants/paths";
import styles from "./Navbar.styles";
import { TextField, Typography } from "@material-ui/core";
import { height } from "@mui/system";

const useStyles = makeStyles(styles);

function AccountMenu() {
  const classes = useStyles();
  const [anchorEl, setMenu] = useState(null);
  const firebase = useFirebase();

  function closeAccountMenu() {
    setMenu(null);
  }
  function handleMenuClick(e) {
    setMenu(e.target);
  }
  function handleLogout() {
    closeAccountMenu(); // redirect to '/' handled by UserIsAuthenticated HOC
    return firebase.logout();
  }

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setMenu(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? "menu-appbar" : null}
        aria-haspopup="true"
        onClick={handleMenuClick}
        classes={{ root: classes.accountButton }}
        size="medium"
        style={{ marginTop: "-5px" }}
      >
        <MenuIcon
          fontSize="large"
          style={{ stroke: "lightgrey", strokeWidth: "2" }}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}
      >
        {/* <MenuItem
          component={HashLink}
          smooth
          to={REEL_PATH}
          onClick={closeAccountMenu}
          data-test="showreel"
        >
          Showreel
        </MenuItem> */}

        {/* <MenuItem
          component={HashLink}
          smooth
          to={TEAM_PATH}
          onClick={closeAccountMenu}
          data-test="team"
        >
          Team
        </MenuItem> */}

        {/* <MenuItem
          component={HashLink}
          smooth
          to={HOME_PATH}
          onClick={handleOpen}
          data-test="vacature"
        >
          Vacature
        </MenuItem> */}

        <MenuItem
          component={Link}
          to={LOGIN_PATH}
          onClick={closeAccountMenu}
          data-test="sign-in"
        >
          Sign In
        </MenuItem>

        {/* <MenuItem component={Link} to={ACCOUNT_PATH} onClick={closeAccountMenu}>
          Account
        </MenuItem> */}

        {/* <MenuItem component={Link} to={USERS_PATH} onClick={closeAccountMenu}>
          Gebruikers
        </MenuItem> */}

        {/* <MenuItem
          component={Link}
          to={ORGANISATIONS_PATH}
          onClick={closeAccountMenu}
        >
          Organisaties
        </MenuItem>
        <MenuItem
          component={Link}
          to={BOOKINGS_PATH}
          onClick={closeAccountMenu}
        >
          Boekingen
        </MenuItem>

        <MenuItem
          component={Link}
          to={PLANNERSCHEDULE_PATH}
          onClick={closeAccountMenu}
        >
          Planner Schedule
        </MenuItem>
        <MenuItem
          component={Link}
          to={BOOKERSCHEDULE_PATH}
          onClick={closeAccountMenu}
        >
          Booker Schedule
        </MenuItem>
        <MenuItem
          component={Link}
          to={PUSHMESSAGETEST_PATH}
          onClick={closeAccountMenu}
        >
          Push Message Test
        </MenuItem> */}
        {/* <MenuItem
          component={Link}
          to={AVAILABILITY_PATH}
          onClick={closeAccountMenu}
        >
          Beschikbaarheid
        </MenuItem> */}
        {/* <MenuItem onClick={handleLogout}>Log Uit</MenuItem> */}
      </Menu>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        // style={{ width: "1200px" }}
      >
        <DialogTitle id="simple-dialog-title">Werken bij HPM</DialogTitle>
        <img
          style={{ objectFit: "cover", maxWidth: "100%", height: "auto" }}
          src={Vacature}
          alt="vacature"
        />
        <Typography style={{ margin: "20px" }}>
          Onze cameramensen werken in binnen- en buitenland voor nieuws- en
          actualiteitenrubrieken zoals NOS journaal, NOS Jeugdjournaal en NOS
          Stories. We zoeken een topper van een cameravrouw/-man (fulltime). Heb
          jij ENG ervaring op FS7 en/of XDCAM, affiniteit met nieuws en
          actualiteiten en kun je monteren op Avid en/of Premiere Pro? Ben jij
          in de praktijk niet uit het veld te slaan en woon je in (de omgeving)
          Hilversum? Stuur een motivatie en je cv naar info@hpm.nl
        </Typography>
      </Dialog>
    </>
  );
}

export default AccountMenu;
