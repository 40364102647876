export default {
  palette: {
    primary: {
      main: "#2196f3",
    },
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexColumnLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};
