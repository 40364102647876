// export const LIST_PATH = "/projects";
export const ACCOUNT_PATH = "/account";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const ORGANISATIONS_PATH = "/organisations";
export const RELATIONS_PATH = "/relations";

export const BOOKERORGANISATION_PATH = "/BookerOrganisation";
export const PLANNERORGANISATION_PATH = "/PlannerOrganisation";

export const BOOKINGS_PATH = "/bookings";
export const PLANNERSCHEDULE_PATH = "/plannerschedule";
export const BOOKERSCHEDULE_PATH = "/bookerschedule";
export const CREWSCHEDULE_PATH = "/crewschedule";
export const CREWVIEWONLY_PATH = "/crewscheduleviewonly";
export const BOOKERSCHEDULEALL_PATH = "/bookerscheduleall";

export const USERS_PATH = "/users";
export const MAP_PATH = "/map";

export const PUSHMESSAGETEST_PATH = "/pushmessagetest";
export const AVAILABILITY_PATH = "/z5beschikbaarheid";
export const AVAILABILITY_XD_PATH = "/xdbeschikbaar";
export const AVAILABILITY_FS_PATH = "/fsbeschikbaar";
export const AVAILABILITY_PATH_JOURNAAL = "/n3beschikbaarheidjournaal";
export const AVAILABILITY_PATH_RTL = "/q6beschikbaarheidrtl";
export const AVAILABILITY_PATH_NOS = "/j8beschikbaarheidnos";
export const AVAILABILITY_PATH_NIEUWSUUR = "/f4beschikbaarheidnieuwsuur";
export const AVAILABILITY_PATH_EENVANDAAG = "/x9beschikbaarheideenvandaag";
export const AVAILABILITY_PATH_GENERAL = "/q1beschikbaarheidalgemeen";

export const HOME_PATH = "/";
export const TEAM_PATH = "/#team";
export const REEL_PATH = "/#reel";
